const tinymceConfig = {
  styles: `
    h1 {
      font-size: 24px;
      font-weight: 500;
      margin-bottom: 15px;
      color: #ec6424 !important;
    }
    
    h2 {
      font-size: 20px;
      font-weight: 500;
      margin-bottom: 10px;
      margin-top: 20px;
      color: #0e76bc;
    }
    
    p {
      font-size: 16px;
      color: #161f27;
    }
    
    a {
      font-size: 16px;
      color: #0026ca;
      text-decoration: none;
    }

    .mce-btn-group .mce-btn {
      float: left;
    }

    /* Scrollbar width */
    ::-webkit-scrollbar {
      width: 16px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #8593a8;
      border-radius: 12px;
      border-bottom: 4px solid white;
      border-left: 4px solid white;
      border-right: 4px solid white;
      border-top: 4px solid white;
    }
  `,
  apiKey: "4t2jtrarzmgms5y58vare6uujypx3nb17wh4x9g5i7xz9qas",
  toolbarOptions: "undo redo | bold italic underline backcolor | formatselect | alignleft aligncenter alignright alignjustify | outdent indent | numlist bullist | removeformat | ltr rtl",
};

export default tinymceConfig;
